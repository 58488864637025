.dummyAPI {
  flex-direction: column;
  > * {
    margin-bottom: 20px;
  }
  .apis {
    flex-flow: wrap;
    margin-top: 10px;
    > * {
      margin-right: 10px;
    }
  }
}
.thumbnail2 {
  border: 1px solid green;
  border-radius: 10px;
  overflow: hidden;
  // padding: 10px;
  margin-right: 10px;
  p {
    margin: 0 0 10px;
    white-space: pre;
  }
  i {
    font-style: italic;
  }
  b {
    font-weight: bold;
  }
  > .innerThumbnail {
    transform-origin: top left;
  }
}
.thumbnails {
  display: flex;
  flex-wrap: wrap;
}
