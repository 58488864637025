.card__header {
  background-color: #f6f8fa;
  //   width: 100%;
  color: #717985;
  height: 54px;
  border-radius: 6px 6px 6px 6px;
  justify-content: center;
}

.scenario__card__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: calc(100% - 55px);
  margin-left: -8px;
  margin-right: -8px;
  width: calc(100vw - 296px) !important;
}

.card {
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
}

.card__item {
  flex: 1 1 380px;
  max-height: 396px;
  width: 380px;
  height: 396px;
  margin: 0px 12px 48px 12px;
}

.card__item__placeholder {
  flex: 1 1 380px;
  max-height: 396px;
  width: 380px;
  height: 396px;
  margin: 0px 12px 48px 12px;
}

.thumbnail__container {
  // border: 1px solid #e9ecef;
  border-radius: 8px;
  width: 90%;
  padding: 10px;
  // height: 70%;
}

.placeholder {
  visibility: hidden;
}
