// Text Ellipsis
@mixin textEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: normal;
  white-space: nowrap;
}

// Text LineClamp (Default: 2)
@mixin textLineClamp($line: 2) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  overflow: hidden;
}

// Custom ScrollBar
@mixin customScroll($scrollWidth: 6px, $scrollHeight: 0) {
  &::-webkit-scrollbar {
    width: $scrollWidth;
    height: $scrollHeight;
  }
  &::-webkit-scrollbar-button:start:decrement,
  &::-webkit-scrollbar-button:end:increment {
    display: block;
    height: 0;
    width: 0;
    background: transform;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 14px 14px transparent;
    background: transform;
    border: 6px solid transparent;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 14px 14px #c2c2c2;
    background: rgba(37, 45, 56, 0.4);
    border-radius: 3px;
    -webkit-border-radius: 3px;
  }
}

// Set AvatarStyle
@mixin setAvatarStyle($bgColor, $txtColor) {
  .avatar-column {
    background-color: $bgColor;
    color: $txtColor;
    border: 1px solid darken($bgColor, 5%);
  }

  &.avatar-picture {
    .avatar-column {
      border-width: 0;
    }
  }
  &.avatar-team {
    .avatar-column {
      background-color: $txtColor;
      color: $FASOOWhite100;
      border-width: 0;
    }
  }
  &.avatar-side {
    .avatar-column {
      background-color: $txtColor;
      color: $bgColor;
      margin-right: 12px;
      border-radius: 8px;
      border-width: 0;
    }
  }
}
