.note-editable b,
.note-editable strong {
  font-weight: bold;
}
.note-editable b {
  font-weight: 800;
}
.note-editable i {
  font-style: italic;
}
.note-editable ul {
  list-style: disc !important;
  list-style-position: inside !important;
}

.note-editable span {
  line-height: normal;
}
.note-editable ol {
  list-style: decimal !important;
  list-style-position: inside !important;
}
.panel-default > .panel-heading {
  background-color: transparent;
  border-color: transparent;
}
.btn {
  color: #252d38;
  background-color: #ebeff2;
  height: 40px;
  border-radius: 8px;
  border-style: none;
  cursor: pointer;
  &:hover {
    background-color: #d1d6db;
  }
}
.panel-default {
  width: 100%;
}
.note-editor.note-frame {
  border-radius: 8px;
}
