@import "../mixin.scss";
@import "../variable.scss";

.main {
  padding: 32px;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.sidemenu {
  overflow-y: auto;
  z-index: 5;
  min-width: 240px;
  width: 240px;
  height: 100%;
  @include customScroll(0px, 0px);
  padding: 24px 12px 0px 12px;
  .hr {
    width: 100%;
    border-top: 1px solid #e2e5e9;
  }

  &-moreMenu {
    padding: 16px;
    width: 220px;
    height: auto;
    border: 1px solid $FASOOBorder;
    border-radius: 8px;
    background-color: $FASOOWhite100;
    z-index: 10;

    &-item {
      height: 40px;
      border-width: 1px;
      padding: 4px 8px;
      align-items: center;
      vertical-align: middle;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: $FASOOButtonNomal;
      }
    }
  }
}

.modal-content {
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: none;
  border-radius: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.modal-root > .inner > .modal-head {
  background-color: #ffffff !important;
  height: 80px !important;
  padding: 24px 20px 24px 28px !important;
  path {
    stroke: $FASOOTextMain;
  }
}

.modal-root > .inner > .modal-content {
  padding: 8px 28px !important;
  gap: 32px;
}

.modal-root > .inner > .modal-foot {
  padding: 24px 28px !important;
  height: 88px !important;
}

.setting-button-list {
  padding: 8px !important;
  &-search {
    padding: 8px 6px 8px 6px;
    margin-bottom: 8px;
    border: 1px solid #e9ecef;
    border-radius: 8px;
  }
  &-body {
    display: list-item;
    height: 200px;
    flex-direction: column;
    overflow-y: auto;
    @include customScroll;
    &-item {
      padding: 8px 6px 8px 6px;
      height: 40px;
    }
  }
  &-footer {
    margin: 0px -8px 0px -8px;
    padding: 12px 16px 4px 16px;
    border-top: 1px solid #e9ecef;
  }
}
.tab-box {
  width: 100%;
}
.mindsatSearch {
  .searchrootbar > .input-area > .text-field-root .text-field-input-inner {
    max-width: 100%;
    padding: 0 12px 0 20px;
  }
}
.mindsatTable {
  &-item {
    .checkbox-root,
    .wrapsodyCheckbox-root {
      display: none;
      &.checked {
        display: table;
      }
    }
    &:hover {
      .checkbox-root,
      .wrapsodyCheckbox-root {
        display: table;
      }
    }
  }
}
.tab-panel-root {
  &.on {
    height: calc(100% - 33px);
  }
}
