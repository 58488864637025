.denied {
  width: 100%;
  height: 100%;
  &-header {
    cursor: pointer;
    width: 100%;
    height: 60px;
    justify-items: center;
    align-items: center;
    place-content: center;
    border-bottom: 1px solid #edeff3;
  }
}
