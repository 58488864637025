.img__thumb__contaier {
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(4, 77.5px);
  height: 240px;
  width: fit-content;
}
.add__thumb {
  width: 77.5px;
  height: 77.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: #d1d6db;
  }
}

.img__thumb {
  background-size: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  &:hover {
    cursor: pointer;
    opacity: 0.3;
  }
}

.selected__image {
  width: 32px;
  height: 32px;
  background-size: cover;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 4px;
}

.selected__tooltip__image {
  width: 77.5px;
  height: 77.5px;
  background-size: cover;
  object-fit: cover;
}
.category__select {
  position: relative;
}

.category__select__list {
  cursor: pointer;
  z-index: 100;
  position: absolute;
  top: 45px;
  width: auto;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid #b5bbc2;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.category__select__item {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 10px;
  justify-content: space-between;
}

.select__insert {
  display: flex;
  height: 40px;
  align-items: center;
  line-height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 8px;
  border-bottom: 1px solid #ebeff2;
  // &:hover {
  //   background: #ebeff2;
  //   stroke: #ebeff2;
  // }
}

.contents__contaier {
  // height: 240px;
  flex-direction: column;
  div {
    display: flex;
    height: 40px;
    align-items: center;
    line-height: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background: #ebeff2;
      stroke: #ebeff2;
    }
  }
  .selected {
    color: #3182f6 !important;
    span {
      color: #3182f6 !important;
    }
    font-weight: bold;
    background: #e8f3ff;
    &:hover {
      background: #e8f3ff;
      stroke: #ebeff2;
    }
  }
}

.write__catgeory__list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
