body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: "NotoSansKR";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  > .root {
    height: 100%;
  }
}

code {
  font-family: "NotoSansKR";
}
