@import "../../../variable.scss";
@import "../../../mixin.scss";

.dot__container {
  display: flex;
  align-items: center;
}
.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 7px;
}
.blue__dot {
  background-color: $FASOOGreen;
}
.secondary__dot {
  background-color: $FASOOIcon3;
}
.green__dot {
  background-color: #05c072;
}
.red__dot {
  background-color: $FASOOTextRed;
}
.yellow__dot {
  background-color: $FASOOStateHold;
}
.gray__dot {
  background-color: $FASOOIcon3;
}

.result__scroll__table {
  @include customScroll;
  will-change: auto !important;
  div tr {
    display: grid;
    grid-template-columns: 76px 250px 120px 1fr 80px 160px 160px 80px 120px 80px 75px; /* 열 너비 설정 */
    width: 100%;
    td {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:first-child {
        padding: 0px 16px;
      }
    }
  }
}

.result__scroll__table.en {
  div tr {
    display: grid;
    grid-template-columns: 76px 250px 120px 1fr 80px 160px 160px 80px 120px 96px 91px; /* 열 너비 설정 */
  }
}
