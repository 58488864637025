.progress-root {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  // width: 100%;
  // height: 100%;
  // padding-top: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5050;
}

@keyframes ldio-j3o061p6lyr {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(0.6, 0.6);
  }
  100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);
  }
}
.ldio-j3o061p6lyr div > div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #3b94e2;
  animation: ldio-j3o061p6lyr 1s linear infinite;
}
.ldio-j3o061p6lyr div:nth-child(1) > div {
  left: 124px;
  top: 92px;
  animation-delay: -0.875s;
}
.ldio-j3o061p6lyr > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 132px 100px;
}
.ldio-j3o061p6lyr div:nth-child(2) > div {
  left: 115px;
  top: 115px;
  animation-delay: -0.75s;
}
.ldio-j3o061p6lyr > div:nth-child(2) {
  transform: rotate(45deg);
  transform-origin: 123px 123px;
}
.ldio-j3o061p6lyr div:nth-child(3) > div {
  left: 92px;
  top: 124px;
  animation-delay: -0.625s;
}
.ldio-j3o061p6lyr > div:nth-child(3) {
  transform: rotate(90deg);
  transform-origin: 100px 132px;
}
.ldio-j3o061p6lyr div:nth-child(4) > div {
  left: 69px;
  top: 115px;
  animation-delay: -0.5s;
}
.ldio-j3o061p6lyr > div:nth-child(4) {
  transform: rotate(135deg);
  transform-origin: 77px 123px;
}
.ldio-j3o061p6lyr div:nth-child(5) > div {
  left: 60px;
  top: 92px;
  animation-delay: -0.375s;
}
.ldio-j3o061p6lyr > div:nth-child(5) {
  transform: rotate(180deg);
  transform-origin: 68px 100px;
}
.ldio-j3o061p6lyr div:nth-child(6) > div {
  left: 69px;
  top: 69px;
  animation-delay: -0.25s;
}
.ldio-j3o061p6lyr > div:nth-child(6) {
  transform: rotate(225deg);
  transform-origin: 77px 77px;
}
.ldio-j3o061p6lyr div:nth-child(7) > div {
  left: 92px;
  top: 60px;
  animation-delay: -0.125s;
}
.ldio-j3o061p6lyr > div:nth-child(7) {
  transform: rotate(270deg);
  transform-origin: 100px 68px;
}
.ldio-j3o061p6lyr div:nth-child(8) > div {
  left: 115px;
  top: 69px;
  animation-delay: 0s;
}
.ldio-j3o061p6lyr > div:nth-child(8) {
  transform: rotate(315deg);
  transform-origin: 123px 77px;
}
.loadingio-spinner-spin-3p9ce1185f9 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  // background: #ffffff;
}
.ldio-j3o061p6lyr {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-j3o061p6lyr div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
