// Variables(add variables)
$FASOOBlack100: #000000;
$FASOOBlack10: lighten($FASOOBlack100, 90%);

$FASOOWhite100: #ffffff;

$FASOOGreen: #3182f6;
$FASOORed: #f6705e;
$FASOOOrange: #f6aa2e;
$FASOOViolet: #8d65fc;
$FASOOCyan: #27dbd8;
$FASOOBlue: #4c86f4;
$FASOOYellow: #e5be00;
$FASOOAppleGreen: #7dad31;

// Background
$FASOOBgGreen: #e9f8f0;
$FASOOBgRed: #ffe3e7;
$FASOOBgOrange: #fff0dc;

// Text
$FASOOIcon1: #252d38;
$FASOOIcon2: #717985;
$FASOOIcon3: #b5bbc2;
$FASOOIcon4: #d1d6db;
$FASOOTextRed: #ef5350;

$FASOOStateApporve: #3182f6;
$FASOOTextMain: #252d38;
$FASOOButtonHover: #1b64da;
$FASOOMainBlue: #3182f6;
$FASOOToastSuccess: #3569d7;
$FASOOTooltipBg: #4b596b;
$FASOOTextSecond: #717985;
$FASOOScrollThumb: #8a9099;
$FASOOTextDesc: #b5bbc2;
$FASOOButtonDisabled: #adcdfb;
$FASOOTextDisabled: #d1d6db;
$FASOOBorder: #dadee5;
$FASOOBorderLight: #edeff3;
$FASOOButtonGreenLight: #e8f3ff;
$FASOOMainBlueLight: #e8f3ff;
$FASOOButtonNomal: #e9ecef;
$FASOOTableHeader: #f6f8fa;
$FASOOStateHold: #faa131;

$FASOOButtonClick: #1b64da;

$FASOOAccordianBackground: #effaf4;
$FASOOAccordianClick: #30c56e;
$FASOOSideMenuBackground: #f8f9fa;

$FASOOTimelineFilterText: #717985;
