@import "../../mixin.scss";

.mail-body {
  // border: 1px solid #d1d6db;
  border-radius: 8px;
  padding: 12px;
  max-height: 400px;
  line-height: normal;
  // background-color: white;
  width: 100%;
  height: 100%;
  @include customScroll(3px, 0px);
  overflow: hidden;
  opacity: 1;
  &.overflow {
    overflow: auto;
  }
  &--inner {
    color: black;
    p {
      color: black;
    }
  }
}
.tooltip-react {
  opacity: 1 !important;
}
