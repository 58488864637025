.reportSetting {
  .tab-box {
    height: calc(100% - 170px);
  }
  .tab-panel-root {
    height: 100%;
  }
  .tempate-header {
    min-height: 37px;
  }
}
