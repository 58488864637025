.solve__quiz__main {
  background-color: #f6f8fa;
}

.solve__quiz__container {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 12px 0px #191f281f;
  padding: 28px 40px;
}

// .radio-root {
//   margin-left: 0 !important;
// }

// .btn-root.btn-icon > svg {
//   margin-right: 0 !important;
// }

.quiz__solve__tag.tag-green .tag-column {
  color: #05c072 !important;
  font-weight: 400;
}

.quiz__comment__container {
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 10px 12px;
  margin-top: 28px;
}

.correct__answer > label > .radio-label {
  color: #05c072;
}

.wrong__answer > label > .radio-label {
  color: #ef5e4b;
}

.quiz__solve__comments__container {
  line-height: 1.4;
}

.quiz__solve__arrow__button {
  border-radius: 8px !important;
  border: 0 !important;
}

.quiz__solve__arrow__button:disabled {
  background-color: #e9ecef !important;
}

.quiz__solve__OX {
  margin-left: 0 !important;
}
