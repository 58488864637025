@import "./variable.scss";

.header {
  padding: 0 32px 0 24px;
  height: 60px;
  &.on {
    border-bottom: 1px solid #edeff3;
  }
}
a {
  color: $FASOOTextMain;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.btn-color-activeGreen {
  > span {
    color: $FASOOGreen;
  }
  &:hover {
    > span {
      color: $FASOOWhite100;
    }
  }
}
.accordion-root .accordion__item--clicked {
  border-radius: 10px;
}
a:focus,
a:hover {
  color: $FASOOTextMain;
}
.accordion__item__tree__depth {
  width: calc(100% - 32px) !important;
}
th {
  color: $FASOOTextSecond;
  font-size: 13px;
}

thead > tr {
  th:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }
  th:last-child {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
}

tbody > tr {
  // &:hover {
  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  // }
}

tbody > tr > td {
  font-size: 14px;
  color: $FASOOIcon1;
}

.detail__text {
  height: 40px;
}

.category__container {
  width: 100%;
  max-height: 200px;
  display: flex;
  flex-wrap: wrap;
}

.btn-root.btn-color-tertiary-click > span {
  color: #3182f6;
}

.setting-button.setting-button__type-button.on > button > .btn-root > span {
  color: #3182f6;
}
.react-datepicker__day-name {
  width: calc(37px - 0.7rem) !important;
}
.setting-button-list {
  overflow-y: auto !important;
}
