.quiz__radio.radio-root > label::before {
  background-color: white;
}
.prompt {
  background-color: #f6f8fa;
  width: 100%;
  height: 180px;
  padding: 16px 20px;
  border-radius: 8px;
  margin-top: 18px;
}
.aiPrompt {
  .modal-content {
    > div {
      width: calc(100% + 20px) !important;
    }
  }
  &-body {
    padding-right: 20px;
  }
  &-quizAnswer {
    border-radius: 8px;
    width: 100%;
    background-color: #f6f8fa;
    padding: 16px 20px;
    > * + * {
      margin-top: 28px;
    }
  }
  &-question {
    min-width: 100px;
    max-width: 100px;
  }
  &-answer {
    width: calc(100% - 100px);
    margin-left: 16px;
    white-space: normal;
  }
}
