@import "../../mixin.scss";
@import "../../variable.scss";

.organ {
  &-tree {
    height: calc(100%);
    overflow-y: auto;
    width: 263px;
    margin-right: 32px;
    padding-right: 3px;
    @include customScroll;
  }
  .accordion__item__tree__depth {
    width: calc(100% - 32px);
  }
  &-body {
    width: calc(100% - 292px);
    height: 100%;
    flex-direction: column;
  }
}
.existUSER {
  td {
    color: $FASOOTextDisabled;
  }
}
