.login {
  width: 100%;
  justify-content: space-evenly;
  padding: 0 150px;
  @media (max-width: 1250px) {
    flex-direction: column;
    .login-img {
      display: none;
    }
  }
  &-logo {
    margin-bottom: 40px;
  }
  &-form {
    flex-direction: column;
    width: 360px;
    margin-bottom: 18px;
    > * + * {
      margin-top: 12px;
      margin-bottom: 10px;
    }
  }
  &-under {
    margin-top: 18px;
    justify-content: space-between;
    width: 100%;
  }
}
.hiddenEye {
  margin-right: 6px;
  margin-top: 1px;
}
