@import "../../../mixin.scss";

.reportView {
  .searchrootbar-root {
    width: 200px;
  }
  .text-field {
    padding-left: 22px !important;
  }
  .text-field-input-inner {
    max-width: 100% !important;
  }
  &-detail {
    padding-bottom: 32px;
    &--item {
      padding-right: 40px;
      margin-bottom: 28px;
    }
  }
}
.checkbox-type--select {
  padding: 8px;
  .checkbox-root {
    margin-left: 0;
    margin-top: 12px;
  }
}
.mail_ccList {
  border: 1px solid #dadee5;
  border-radius: 8px;
  flex-wrap: wrap;
  margin-top: 8px;
  min-height: 40px;
  padding: 8px;
  width: 100%;
}

.report__scroll__table {
  @include customScroll;
  div tr {
    display: grid;
    grid-template-columns: 55px 1fr 230px 230px 100px 120px 145px; /* 열 너비 설정 */
    width: 100%;
    td {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:first-child {
        padding: 0px 16px;
      }
    }
  }
}
