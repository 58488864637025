.accordion__item__tree {
  &:hover {
    .moreItem {
      visibility: visible;
    }
  }
}
.accordion_arrowDown {
  > path {
    fill: #717985 !important;
  }
}
.moreItem {
  visibility: hidden;
  &.hovered {
    visibility: visible;
  }
}
.setting-button .setting-button-list > ul > li > span {
  font-weight: normal;
}
