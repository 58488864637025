@import "../../variable.scss";

.dashboard {
  &__box {
    border-radius: 20px;
    padding: 24px;
    min-width: 260px;
    width: 100%;
    height: 108px;
    align-items: center;
    justify-content: space-between;
  }
  &__boxes {
    margin-top: 24px;
    > * + * {
      margin-left: 8px;
    }
  }
  &_toplist {
    padding: 12px 16px;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      background-color: $FASOOButtonNomal;
    }
  }
}
.blue__box {
  background-color: $FASOOGreen;
}
.mint__box {
  background-color: $FASOOCyan;
}
.yellow__box {
  background-color: $FASOOStateHold;
}
.red__box {
  background-color: $FASOOTextRed;
}
.green__box {
  background-color: #05c072;
}
.purple__box {
  background-color: #9b77fc;
}
.selected-table {
  background-color: $FASOOButtonGreenLight;
  cursor: pointer;
  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
.configChart {
  background-color: $FASOOTableHeader;
  border-radius: 10px;
  padding: 12px;
  width: 50%;
  height: 100%;
  margin-left: 32px;
  flex-direction: column;
  &-body {
    height: 504px;
    width: 100%;
    background-color: white;
    padding: 12px;
    border-radius: 8px;
    flex-direction: column;
  }
}
