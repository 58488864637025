.resend__button {
  //   position: fixed;
  //   right: 5%;
  display: none !important;
}

.resend__container {
  cursor: pointer;
}
.resend__container:hover .resend__button {
  display: block !important;
  background-color: #d1d6db;
  padding: 6px 12px;
  border-radius: 8px;
  cursor: pointer;
}

.resend__container:hover .resend__text {
  display: none;
}

.filter__tag__container {
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: flex-start;
}
