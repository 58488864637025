.category__tag {
  background-color: #ebeff2;
  height: 24px;
  border-radius: 50px !important;
  margin-right: 4px;
  margin-bottom: 4px;
  width: fit-content;
  max-width: 400px;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: fit-content;
  .filterTag-root {
    padding: 6px;
    font-size: 12px;
  }
  button {
    margin-right: 0 !important;
  }
  button > div > svg {
    width: 12px;
    height: 12px;
  }
}
