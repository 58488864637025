@import "../../../variable.scss";

.default-table {
  th {
    color: $FASOOTextSecond;
    font-size: 13px;
  }

  tbody > tr {
    &:hover {
      td:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      td:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  tbody > tr > td {
    font-size: 14px;
    color: $FASOOIcon1;
  }

  .template__check {
    color: $FASOOIcon4 !important;
    &:hover {
      color: $FASOOButtonDisabled !important;
    }
  }
}
.accordion-root {
  width: 100%;
}
.training__accordian {
  div > .accordion__item__header {
    button {
      width: 100%;
      height: 54px;
      padding: 0 10px !important;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.gridList {
  flex-flow: wrap;
  display: flex;
  > * {
    margin-right: 4px;
    margin-bottom: 4px;
  }
}

.scenario__tag {
  height: 24px;
  background-color: #ebeff2;
  border-radius: 50px;
  font-size: 12px;
  width: fit-content;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  max-height: 24px;
  span {
    max-width: 400px;
  }
}

.no__selected__scenario__box {
  background-color: #f6f8fa;
  border-radius: 8px;
}
.tag-gray {
  > .tag-column {
    font-size: 12px;
  }
}

.deleted__scenario {
  color: #b5bbc2 !important;
  text-decoration: line-through;
}

.scenario__select__item {
  white-space: pre;
}

.scenario__tooltip__template {
  word-break: break-all;
}
