.htmlThumbnail {
  //
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  color: black;
  border-radius: 10px;
  // padding: 10px;
  margin-right: 10px;
  p {
    margin: 0 0 10px;
    white-space: pre;
  }
  i {
    font-style: italic;
  }
  b {
    font-weight: bold;
  }
  a {
    color: #337ab7;
    text-decoration: underline;
  }
  > .innerThumbnail {
    transform-origin: top left;
  }
}
.thumbnails {
  display: flex;
  flex-wrap: wrap;
}
