.selected__user__container {
  width: 598px;
  height: 248px;
  border: 1px solid #d1d6db;
  border-radius: 8px;
  padding: 8px;
}

.quizSelectModal {
  .scroll-box {
    border-radius: 8px;
    border: 1px solid #d1d6db;
  }
}

.quiz__select__button.btn-root.btn-color-secondary[disabled] {
  background-color: #e9ecef !important;
}
