.sampleUI {
  &-left {
    width: 200px;
    font-size: 20px;
    font-weight: 700;
  }
  &-right {
    width: calc(100% - 200px);
  }
}
