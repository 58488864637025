@import "../../../variable.scss";
@import "../../../mixin.scss";

.registUpdateUserModal {
  .select-root > .select-list {
    max-height: 220px;
  }
}
.selectbox {
  width: 100%;
  display: block;
  position: absolute !important;
  max-height: 300px;
  z-index: 100;
  border: 1px solid $FASOOBorder;
  background: $FASOOWhite100;
  box-shadow: 0px 4px 12px rgba(25, 31, 40, 0.12);
  border-radius: 8px;
  padding: 8px;
  font-size: 12px;
  cursor: pointer;

  white-space: nowrap;

  > ul > li {
    border-radius: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      background-color: $FASOOButtonGreenLight;
    }
    &:focus-visible {
      background-color: $FASOOButtonGreenLight !important;
      outline: none !important;
      //border-radius: none !important;
    }
    > span {
      @include textEllipsis;
      position: relative;
      color: $FASOOIcon1;
      display: flex;
      align-items: center;
      height: 100%;
      line-height: normal;
      text-decoration: none;
      text-align: left;
      padding: 0 8px;
      cursor: pointer;
      > svg {
        margin-right: 8px;
      }
      &:focus-visible {
        background-color: $FASOOButtonGreenLight !important;
        outline: none !important;
        //border-radius: none !important;
      }
    }
  }
  > ul > .divider {
    margin: 4px auto;
  }
  .user-info-bar {
    padding: 0 8px;
    margin: 10px 0;
  }
}
.access__submenu__container {
  border: 1px solid #e9ecef;
  width: 100%;
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  padding: 12px;
  border-radius: 8px;
  margin: 20px 0;
  gap: 20px;
}

.access__submenu__container .checkbox-root {
  margin: 0 !important;
}
